<template>
  <b-card v-if="SINGLE_EXPERT_EVENT && loaded" :title="$t('Event Payment')">
    <b-row class="pt-3 m-0">
      <b-col md="4" class="mb-4 pl-0">
        <h5 class="d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill :variant="stepsDone.stepDate" class="mr-1 badge-step-one">1</b-badge>
          {{ $t('Events dates') }}
        </h5>
        <ul class="slots pl-0 table-bordered" v-if="dateTime">
          <li class="slots__header row m-0">
            <div class="slots__header__count col-2"></div>
            <div class="slots__header__date col-5">{{ $t('Date') }}</div>
            <div class="slots__header__time col-5">{{ $t('Time') }}</div>
          </li>
          <!--header table end-->
          <li class="row slots__item m-0" v-for="(slot, index) in dateTime">
            <div class="slots__item__count col-2"><span class="badge badge-success badge-pill"> {{ index + 1 }} </span>
            </div>
            <div class="select-data col-10" v-if="!dateTime[index].start_time">
              <b-badge v-b-toggle="'collapse'+index"
                       class="cursor-pointer text-wrap"
                       variant="light-warning"
              >
                {{ $t('Please select date & time for') }} {{ dateTime[index].title }}
              </b-badge>
            </div>
            <div v-if="dateTime[index].date && dateTime[index].start_time " class="slots__item__date col-5">
              <b-badge v-b-toggle="'collapse'+index" variant="light-success">{{ dateTime[index].date | moment }}</b-badge>
            </div>
            <div v-if="dateTime[index].date && dateTime[index].start_time" class="slots__item__time col-5">
              <b-badge v-b-toggle="'collapse'+index" variant="light-success">{{ dateTime[index].start_time }} -
                {{ dateTime[index].end_time }}
              </b-badge>
            </div>
            <b-collapse
                :id="'collapse'+index"
                class="mt-2"
                accordion="slot-list"
            >
              <div class="col-12">
                <h6 class=" w-100 text-center">
                  {{ $t('Pick a date') }}
                </h6>
                <flat-pickr
                    @input="getSlots(index)"
                    name="date"
                    :placeholder="$t('Please select a Date')"
                    v-model="dateTime[index].date"
                    class="form-control d-none"
                    :config="flatPickrConfig"
                />
              </div>
              <div class="col-12 mt-1 shadow-sm bg-white rounded" v-if="dateTime[index].timeslots.length">
                <h6 class=" w-100 text-center pt-2">
                  {{ $t('Book a time slot') }}
                </h6>
                <div class="   pt-1 mb-5  flex-wrap d-flex">
                  <div class="col-6 mb-1" v-for="slot in dateTime[index].timeslots">
                    <b-button
                        :variant="dateTime[index].start_time+'-'+dateTime[index].end_time == slot.start_time+'-'+slot.end_time ? 'success' : 'primary'"
                        @click="setTime(slot.start_time, slot.end_time, index)"
                        size="sm"
                        class="w-100"
                        v-b-toggle=" ['collapse'+(index+1), 'collapse'+index]"
                    >
                      {{ slot.start_time }} - {{ slot.end_time }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-1 shadow-sm bg-white rounded " v-else>
                <h6 class=" w-100 text-center py-2">
                  {{ $t('No time slots , check another day') }}
                </h6>
              </div>

            </b-collapse>
          </li>
        </ul>
      </b-col>
      <b-col md="4" class="mb-4">
        <h5 class=" d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill :variant="stepsDone.stepPayment" class="mr-1">2</b-badge>
          {{ $t('Credit payments') }}
        </h5>
        <b-alert show variant="danger" v-if="CREDITS == 0">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25">{{ $t('You do not have enough credits') }}</span>
            <router-link to="/credits">
              <b-badge pill variant="danger" class="ml-1"> {{ $t('buy Credits') }}</b-badge>
            </router-link>
          </div>
        </b-alert>
        <b-alert show variant="danger" v-if=" CREDITS  <= eventCredit && CREDITS !== 0">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25">You have {{ CREDITS }} credits left!</span>
            <router-link to="/credits">
              <b-badge pill variant="danger" class="ml-1">{{ $t('buy Credits') }}</b-badge>
            </router-link>
          </div>
        </b-alert>
        <b-alert show variant="success" v-if="CREDITS  >= eventCredit  && CREDITS !== 0 ">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25">You have {{ CREDITS }} credits available!</span>
          </div>
        </b-alert>
        <div class="event_cost d-flex align-items-center">
          <div class="icon_cost">
            <feather-icon class="mr-25" icon="DollarSignIcon"/>
          </div>
          <div class="right_side d-flex align-items-center justify-content-between w-100">
            <div class="cost_info_text">
              <p class="info_title mb-0  ">{{ $t('Costs in Credits') }}</p>
              <p class="sub_info mb-0">  {{ $t('Event price') }}</p>
            </div>
            <p class="credit-cost mb-0">{{ eventCredit }} Credits</p>
          </div>
        </div>
      </b-col>
      <b-col md="4" class="mb-4">
        <h5 class=" d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill :variant="stepsDone.stepTerms" class="mr-1">3</b-badge>
          {{ $t('Complete your booking') }}
        </h5>
        <div class="booking-details">
          <ul class="list-unstyled ">
            <li class="mb-1 w-100" v-if="SINGLE_EXPERT_EVENT.cancellation_condition.length"
                v-for="condition in SINGLE_EXPERT_EVENT.cancellation_condition"
            >
              <b-alert variant="warning" show>
                <div class="alert-body">
                  {{ $t('Cancel Condition Text') }}
                </div>
              </b-alert>
            </li>
            <li class="mb-1 w-100">
              <b-alert
                  v-if="termsShow"
                  show
                  dismissible
                  fade
                  class="mb-0"
                  variant="danger"
              >
                <div class="alert-body">
                  <span>You have to accept conditional Term in Order To book the Event</span>
                </div>
              </b-alert>
            </li>
            <li class="mb-1">
              <b-alert
                  v-if="dateCheckShow"
                  show
                  dismissible
                  fade
                  class="mb-0"
                  variant="danger"
              >
                <div class="alert-body">
                  <span>
                Choose {{ this.dateTime.length }} of {{ this.dateTime.length }} appointments to continue
                  </span>
                </div>
              </b-alert>
            </li>
            <li class="mb-1">
              <b-form-checkbox
                  v-model="terms"
              >
                 {{ $t('I have read and agree to the conditional Terms for the Event') }}
              </b-form-checkbox>
            </li>
            <li>
              <b-button
                  :disabled="isBookcable"
                  class="mt-2"
                  variant="success"
                  type="submit"
                  size="md"
                  @click="bookEvent"
              > {{ $t('Book now!') }}
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <sucess-modal :infoData="modalInfo"/>
  </b-card>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BRow,
  BTable,
  VBToggle
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import SucessModal from '@/views/bookings/sucessModal'
import { German } from 'flatpickr/dist/l10n/de'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    SucessModal,
    BRow,
    BCol,
    BCollapse,
    VBToggle,
    BCard,
    BTable,
    BButton,
    BFormDatepicker,
    flatPickr,
    BFormGroup,
    BAlert,
    BBadge,
    BFormCheckbox
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD.MM.YYYY');
    }
  },
  data() {
    return {
      selectedDate: '',
      flatPickrConfig: {
        dateFormat: 'Y-m-d',
        disable: [{
          from: '01-01-0000',
          to: 'today'
        }],
        inline: true,
        locale: German,
        altInputClass: 'd-none'
      },
      dateTime: [],
      timeslots_count: null,
      modalInfo: {
        title: null,
        id: null,
        description: null,
        image: null,
        startData: null
      },
      stepsDone: {
        stepDate: 'secondary',
        stepPayment: 'secondary',
        stepTerms: 'secondary'
      },
      terms: false,
      payment: false,
      dateCheck: null,
      dateCheckShow: false,
      termsShow: false,
      eventCredit: null,
      loaded: false,
      isBookcable: true,

    }
  },
  computed: {
    ...mapGetters(['SINGLE_EXPERT_EVENT', 'AUTH_USER', 'CREDITS']),

  },
  mounted() {
    this.UPDATE_CREDITS()
    this.timeslots_count = this.SINGLE_EXPERT_EVENT.timeslots_count
    this.timeslots = this.SINGLE_EXPERT_EVENT.timeslots
    this.eventCredit = this.SINGLE_EXPERT_EVENT.credits

    if (this.CREDITS >= this.SINGLE_EXPERT_EVENT.credits) {
      this.payment = true
    }
    this.$root.$on('bv::collapse::state', this.collapseWatcher)
    this.setModalData()
    this.createSelectSlotsArray()

  },
  destroyed() {
    this.$root.$off('bv::collapse::state', this.collapseWatcher)
  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT_EVENT', 'BOOK_EVENT', 'UPDATE_CREDITS']),

    collapseWatcher(collapseId, isJustShown) {
      const index = Number(collapseId.replace('collapse', ''))
      if (this.dateTime[index].date && isJustShown) {
        this.getSlots(index)
      }
    },
    setTime(start_time, end_time, index) {
      this.dateTime[index].start_time = start_time
      this.dateTime[index].end_time = end_time
      let timeSlotChecker = this.dateTime.every(item => item.start_time !== '')
      if (timeSlotChecker) {
        this.stepsDone.stepDate = 'success'
        this.dateCheck = true
      } else {
        this.stepsDone.stepDate = 'light-warning'
      }
    },
    createSelectSlotsArray() {
      this.timeslots.forEach((timeslot) => {
        this.dateTime.push(
            {
              'date': '',
              'start_time': '',
              'end_time': '',
              'timeslots': [],
              'interval': timeslot.booking_time,
              'title': timeslot.topic,
              'event_slot_id': timeslot.id
            }
        )
      })
      this.loaded = true
    },
    getSlots(index) {
      this.slotsLoaded = false
      let url = `/client/booking/expert/${this.SINGLE_EXPERT_EVENT.experts[0].id}/event/${this.SINGLE_EXPERT_EVENT.id}/slots`
      this.$http.post(url, {
        date: this.dateTime[index].date,
        'interval': this.dateTime[index].interval
      })
          .then(response => {
            this.dateTime[index].timeslots = response.data.filter((slot) => {
              return this.dateTime.every((item, i) => {
                const itemDateTime = item.start_time + item.date
                const slotDateTime = slot.start_time + this.dateTime[i].date
                return itemDateTime !== slotDateTime || index === i
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY HH:mm')
    },
    setModalData() {
      this.modalInfo.title = this.SINGLE_EXPERT_EVENT.name
      this.modalInfo.image = this.SINGLE_EXPERT_EVENT.image ? this.SINGLE_EXPERT_EVENT.image.url : null

      this.modalInfo.description = this.SINGLE_EXPERT_EVENT.description

    },
    bookEvent() {
      console.debug(this.dateTime)

      let timeSlotChecker = this.dateTime.every(item => item.start_time !== '')
      if (!timeSlotChecker) {
        this.dateCheckShow = true
        this.stepsDone.stepDate = 'danger'
      }
      if (!this.terms) {
        this.termsShow = true
        this.stepsDone.stepTerms = 'danger'
      } else {
        this.termsShow = false
      }
      if (this.terms && this.payment && this.dateCheck) {
        this.modalInfo.startData = this.dateTime[0].date + ' ' + this.dateTime[0].start_time
        this.BOOK_EVENT({
          'event_id': this.SINGLE_EXPERT_EVENT.id,
          'timeslots': this.dateTime
        })
            .then(response => {
              this.modalInfo.id = response.data.id
              this.UPDATE_CREDITS()
              this.$bvModal.show('success-modal')

            })
      }
    },
    bookingErrorsChecker() {
      let timeSlotChecker = this.dateTime.every(item => item.start_time !== '')
      if (this.terms && this.payment && this.dateCheck && timeSlotChecker) {
        this.stepsDone.stepDate = 'success'
        this.stepsDone.stepPayment = 'success'
        this.stepsDone.stepTerms = 'success'
        this.isBookcable = false
      } else {
        this.stepsDone.stepTerms = 'danger'
        this.isBookcable = true
      }
    }
  },
  watch: {
    terms() {
      this.bookingErrorsChecker()
    },
    dateCheck() {
      this.bookingErrorsChecker()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.slots {
  li {
    padding: 0.72rem 2rem;
    border-top: 1px solid #ebe9f1;
  }

  &__header {
    background-color: #f3f2f7;
  }
}


.badge-step-one {
  width: 22px;
}


.event_cost {
  border-radius: 0.358rem;
  background: rgb(75 75 75 / 8%);

  svg {


    transform: scale(1.5);

    stroke: #ea5455 !important;
  }

  .icon_cost {
    border-radius: 0.358rem;
    background: rgba(234, 84, 85, 0.12) !important;
    padding: 15px;
  }

  .right_side {
    padding: 5px;

    .sub_info {
      font-weight: 500;
    }

    .info_title {
      font-weight: 600;
    }

    .credit-cost {
      font-weight: 600;
    }
  }
}

</style>
