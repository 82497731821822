<template>
  <div>

      <b-row v-if="bookingDetails" class="justify-content-between">
        <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
          <b-card :title="bookingDetails.name" class="text-center h-100">
            <b-card-body>
              <b-card-img v-if="bookingDetails.image" :src="bookingDetails.image.url"></b-card-img>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
          <b-card :title="$t('Event location')" class="text-center h-100">
            <b-card-body>
              <b-card-text class="mt-5">{{ bookingDetails.city }} <br>{{ bookingDetails.street }}</b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
          <b-card :title="$t('The event will be hosted by')" class="text-center h-100">
            <b-card-body>
              <b-avatar size="10rem" v-if="bookingDetails.experts[0].expertdetails.avatar"
                          :src="bookingDetails.experts[0].expertdetails.avatar.url" class="expert-details"></b-avatar>

              <feather-icon
                  v-else
                  icon="UserIcon"
                  size="120"
                  class="usericon"
              />
              <b-card-title class="mb-1 mt-2 mb-2 ">{{ bookingDetails.experts[0].name }}</b-card-title>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardImg,
  BCardTitle,
  BCardText,
  BAvatar,

} from "bootstrap-vue";

export default {
  name: "EventBookingInfo",
  props: ['bookingDetails'],
  components: {
    BCard,
    BRow,
    BCol,
    BCardImg,
    BCardTitle,
    BCardBody,
    BCardText,
    BAvatar,

  },
}
</script>

<style scoped>

</style>
