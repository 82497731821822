<template>
  <b-modal id="success-modal" class="pb-5" hide-footer hide-header no-close-on-backdrop>
    <div class="pre_card px-5 d-flex justify-content-center py-2 align-items-center">
      <h4 class="h4 pr-2">
        {{ $t("Thank you for your booking") }} </h4>
      <feather-icon class="mr-25" icon="CheckCircleIcon" style="stroke:#0fd50a;    transform: scale(1.5);"/>
    </div>
    <div class="card-wrp px-5">
      <div class="card">
        <img v-if="infoData.image" :src="infoData.image"
             alt="Card image" class="card-img-top">
        <div class="card-body">
          <h4 class="card-title">{{ infoData.title }}</h4>
          <p class="card-text" v-html="infoData.description"></p>
        </div>
        <div class="card_actions px-2 pb-2 d-flex justify-content-center">
          <b-button
              @click="goToEvent"
              variant="warning">
            {{ $t("Go to Event Room") }}
          </b-button>
        </div>

        <div class="card-bottom" v-if="infoData.startData">
          <div class="list-group-item text-center"> {{ $t("This Event is happening on the") }}: <h6
              class="text-bold mt-1">{{ infoData.startData }}</h6></div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {BButton} from "bootstrap-vue";

export default {
  name: "sucessModal",
  components: {
    BButton
  },
  props: {
    infoData: {
      type: Object,
      required: true,
    }
  },
  methods: {
    goToEvent() {
      this.$router.push({name: 'event-room-show', params: {'booking_id': this.infoData.id}})
    }
  }
}
</script>

<style scoped>


</style>
