<template>
  <div v-if="SINGLE_EXPERT_EVENT">
    <event-booking-info :booking-details="SINGLE_EXPERT_EVENT"></event-booking-info>
    <event-booking-dynamic v-if="eventType == 2"/>
    <event-booking-fixed v-else/>
  </div>
</template>
<script>
import EventBookingDynamic from "@/views/events/booking/EventBookingDynamic";
import EventBookingFixed from "@/views/events/booking/EventBookingFixed";
import {mapActions, mapGetters} from "vuex";
import EventBookingInfo from "@/views/bookings/EventBookingInfo";

export default {
  name: "Booking",
  components: {EventBookingDynamic, EventBookingFixed, EventBookingInfo},
  data() {
    return {
      eventType: null
    }
  },
  computed: {
    ...mapGetters(['SINGLE_EXPERT_EVENT']),

  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT_EVENT']),
  },
  mounted() {
    this.GET_SINGLE_EXPERT_EVENT(this.$route.params.eventid).then(() => {
      this.eventType = this.SINGLE_EXPERT_EVENT.booking_type
    })
  }
}
</script>

