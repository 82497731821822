<template>
  <b-card v-if="SINGLE_EXPERT_EVENT && loaded" :title="$t('Event Payment')">

    <b-row class="pt-3">
      <b-col md="5" class="mb-4">
        <h5 class="d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill variant="success" class="mr-1 badge-step-one">1</b-badge>
          {{ $t('Events dates') }}
        </h5>
        <b-table
            v-if="tableDate.items.length"
            :items="tableDate.items"
            :fields="tableDate.cols"
            ref="selectableTable"
            bordered
            responsive
            class="mb-0">
          <template #cell(index)="data">
            <b-badge pill variant="success">
              {{ data.index + 1 }}
            </b-badge>
          </template>
        </b-table>
        <b-alert
            v-else
            show
            fade
            class="mb-0"
            variant="danger">
          <div class="alert-body">
            <span>{{ $t('No available events dates') }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col md="3" class="mb-4">
        <h5 class=" d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill :variant="stepsDone.stepPayment" class="mr-1">2</b-badge>
          {{ $t("Credit payments") }}
        </h5>
        <b-alert show variant="danger" v-if="CREDITS == 0">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25"> {{ $t('You do not have enough credits') }}</span>
            <router-link to="/credits">
              <b-badge pill variant="danger" class="ml-1">{{ $t('buy Credits') }}</b-badge>
            </router-link>
          </div>
        </b-alert>

        <b-alert show variant="danger" v-if="CREDITS  <= eventCredit && CREDITS !== 0">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25"> {{ $t('You have') }} {{ CREDITS }}  {{ $t('credits left!') }}</span>
            <router-link to="/credits">
              <b-badge pill variant="danger" class="ml-1">{{ $t('buy Credits') }}</b-badge>
            </router-link>
          </div>
        </b-alert>
        <b-alert show variant="success" v-if="CREDITS >= eventCredit  && CREDITS !== 0 ">
          <div class="alert-body">
            <feather-icon class="mr-25" icon="CreditCardIcon"/>
            <span class="ml-25">{{ $t('You have') }} {{ CREDITS }}  {{ $t('credits available!') }}</span>
          </div>
        </b-alert>

        <div class="event_cost d-flex align-items-center">
          <div class="icon_cost">
            <feather-icon class="mr-25" icon="DollarSignIcon"/>
          </div>
          <div class="right_side d-flex align-items-center justify-content-between w-100">
            <div class="cost_info_text">
              <p class="info_title mb-0  "> {{ $t('Costs in Credits') }}</p>
              <p class="sub_info mb-0">{{ $t('Event price') }}</p>
            </div>
            <p class="credit-cost mb-0">{{ eventCredit }} Credits</p>
          </div>
        </div>
      </b-col>
      <b-col md="4" class="mb-4">
        <h5 class=" d-flex align-items-center mb-3 justify-content-center">
          <b-badge pill :variant="stepsDone.stepTerms" class="mr-1">3</b-badge>
          {{ $t("Complete your booking") }}
        </h5>

        <div class="booking-details">
          <ul class="list-unstyled">
            <li class="mb-1" v-if="SINGLE_EXPERT_EVENT.cancellation_condition.length"
                v-for="condition in SINGLE_EXPERT_EVENT.cancellation_condition">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  {{ $t('Cancel Condition Text') }}
                </div>
              </b-alert>
            </li>
            <li class="mb-1">
              <b-alert
                  v-if="termsShow"
                  show
                  dismissible
                  fade
                  class="mb-0"
                  variant="danger"
              >
                <div class="alert-body">
                  <span>You have to accept conditional Term in Order To book the Event</span>
                </div>
              </b-alert>
            </li>
            <li class="mb-1">
              <b-form-checkbox
                  v-model="terms">
                {{ $t('I have read and agree to the conditional Terms for the Event') }}
              </b-form-checkbox>
            </li>
            <li>
              <b-button
                  :disabled="isBookcable"
                  class="mt-2"
                  variant="success"
                  type="submit"
                  size="md"
                  @click="bookEvent"
              > {{ $t('Book now!') }}
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <sucess-modal :infoData="modalInfo"/>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BTable,
  BButton,
  BFormDatepicker,
  BAlert,
  BBadge,
  BFormCheckbox
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import SucessModal from "@/views/bookings/sucessModal";

export default {

  components: {
    SucessModal,
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormDatepicker,
    flatPickr,
    BFormGroup,
    BAlert,
    BBadge,
    BFormCheckbox

  },

  data() {
    return {
      modalInfo: {
        title: null,
        id: null,
        description: null,
        image: null,
        startData: null

      },
      tableDate: {
        cols: [
          {key: 'index', label: ''},
          {key: 'topic',    label: this.$i18n.t("Topic"),},
          {key: 'date',    label: this.$i18n.t("Date"),},
        ],
        items: []
      },
      stepsDone: {
        stepPayment: 'secondary',
        stepTerms: 'secondary'
      },
      terms: false,
      termsShow: false,
      eventCredit: null,
      payment: false,
      loaded: false,
      isBookcable: true

    }

  },
  computed: {
    ...mapGetters(['SINGLE_EXPERT_EVENT', 'AUTH_USER','CREDITS'])

  },
  mounted() {



this.UPDATE_CREDITS()
    this.SINGLE_EXPERT_EVENT.timeslots.forEach(i => {
      let newItem = {
        topic: i.topic,
        date: this.formatter(i.start_date + ' ' + i.start_time) + ' - ' + this.formatter(i.end_date + ' ' + i.end_time)
      }
      this.tableDate.items.push(newItem)
    })

    if (this.SINGLE_EXPERT_EVENT.timeslots.length) {
      this.modalInfo.startData = this.formatter(this.SINGLE_EXPERT_EVENT.timeslots[0].start_date + ' ' + this.SINGLE_EXPERT_EVENT.timeslots[0].start_time)
    }

    this.eventCredit = this.SINGLE_EXPERT_EVENT.credits

    if (this.CREDITS >= this.SINGLE_EXPERT_EVENT.credits) {
      this.payment = true
    }

    this.loaded = true
    this.setModalData()


  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT_EVENT', 'BOOK_EVENT','UPDATE_CREDITS']),

    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY HH:mm')
    }
    ,
    setModalData() {
      this.modalInfo.title = this.SINGLE_EXPERT_EVENT.name
      this.modalInfo.image = this.SINGLE_EXPERT_EVENT.image ? this.SINGLE_EXPERT_EVENT.image.url : null
      this.modalInfo.description = this.SINGLE_EXPERT_EVENT.description

    },
    bookEvent() {

      if (!this.terms) {
        this.termsShow = true
      } else {
        this.termsShow = false
      }

      if (this.terms && this.payment) {

        this.BOOK_EVENT({
          "event_id": this.SINGLE_EXPERT_EVENT.id,
        }).then(response => {
          this.UPDATE_CREDITS()
          this.modalInfo.id = response.data.id
          this.$bvModal.show('success-modal')

        })
      }

    },
  }, watch: {
    terms() {
      if (this.terms && this.payment && this.tableDate.items.length) {
        this.stepsDone.stepPayment = 'success'
        this.stepsDone.stepTerms = 'success'
        this.isBookcable = false
      } else {
        this.isBookcable = true
      }
    },
  }

}
</script>

<style lang="scss" scoped>

.badge-step-one {
  width: 22px;
}


.event_cost {
  border-radius: 0.358rem;
  background: rgb(75 75 75 / 8%);

  svg {

    transform: scale(1.5);
    stroke: #ea5455 !important;
  }

  .icon_cost {
    border-radius: 0.358rem;
    background: rgba(234, 84, 85, 0.12) !important;
    padding: 15px;
  }

  .right_side {
    padding: 5px;

    .sub_info {
      font-weight: 500;
    }

    .info_title {
      font-weight: 600;
    }

    .credit-cost {
      font-weight: 600;
    }
  }
}

</style>
